<template>
  <div>
    <el-row class="dash-board">
      <el-col :span="3" style="display: flex;align-items: center; padding-left:20px">
        <el-button class="el-but2" type="primary" @click="go()" >返回</el-button>
        <el-button type="success" @click="assessment" class="">交作业</el-button>
      </el-col>
    </el-row>
    <el-row class="bg">
      <el-table
        :data="tableData"
        style="width: 100%;">
        <el-table-column
          label="作业提交时间"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.workTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="作业类型"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span v-show="scope.row.type == 1">文件提交</span>
            <span v-show="scope.row.type == 2">文本提交</span>
          </template>
        </el-table-column>
        <el-table-column
          label="作业文本内容"
          align="center"
          width="200" :show-overflow-tooltip='true'>
          <template slot-scope="scope">
            <span>{{ scope.row.stuWork }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="作业分数"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.workGrade }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="作业批改时间"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.updateTime === null">-</span>
            <span v-else>{{ scope.row.updateTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="描述"
          align="center"
          width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.describe }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" v-if="scope.row.workGrade === '未批改'" @click="editWork(scope.row)">修改作业</el-button>
            <el-button type="primary" size="mini" v-else disabled>修改作业</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:20px">
     <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag"
      >
    </el-pagination>
    </el-row>
    <el-dialog
      title="填写作业内容"
      :visible.sync="centerDialogVisible"
      width="600"
      center>
      <el-form :model="form" ref="form">
        <el-form-item label="作业方式：">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">上传附件</el-radio>
            <el-radio :label="2">文本输入</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="form.type==2">
          <el-row>
            <el-form-item label="作业内容：">
              <el-input v-model="form.stuWork" type="textarea" rows="5"  style="width: 85%"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <span class="dialog-footer">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="noAuth()">提交作业</el-button>
          </span>
          </el-row>
        </div>
        <div v-if="form.type==1">
          <el-row>
            <el-form-item label="作业附件：">
              <div class="el-form-item__content">
                <el-upload ref="upload"
                           accept=".xls,.xlsx,.pdf,.doc,.docx,.pptx,.ppt"
                           action="#"
                           :auto-upload="false"
                           :multiple="false"
                           :file-list="fileList"
                           :before-upload="beforeUpload"
                           :http-request="uploadHttpRequest"
                           :on-remove="fileRemove"
                           :on-change="fileChange">
                  <el-button size="small" type="primary">选择文件</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传.xls,.xlsx,.pdf,.doc,.docx,.pptx,.ppt文件，且不超过200M</div>
                </el-upload>
                <el-button type="primary" size="small" @click="submitUpload">点击上传</el-button>
              </div>
            </el-form-item>
            <el-form-item label="作业描述：">
              <el-input v-model="form.describe" style="width: 85%"></el-input>
            </el-form-item>
          </el-row>
          <el-row>
          </el-row>
          <el-row>
            <el-form-item style="text-align:center">
              <el-button @click="centerDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="uploadBt('form')">提交作业</el-button>
            </el-form-item>
          </el-row>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import { upload, submitWork, findSubmitWork, editWork } from '@/api/training.js'
  export default {
    name: 'task',
    data () {
      return {
        radioTwo: 1,
        tableData: [],
        fileList: [],
        centerDialogVisible: false,
        form: {
          id: '',
          url: '',
          stuWork: '',
          describe: '',
          type: ''
        },
        total: 0,
        pageNum: 1,
        pageSize: 10
      }
    },
    mounted () {
      // 钩子函数
      this.getOne()
    },
    watch: {
      dialogVisible: function(currentValue) {
        this.showMapComponent = currentValue
        if (currentValue) {
          this.keyword = ''
        }
      }
    },
    methods: {
      // 返回上一个页面
      go() {
         this.$router.go(-1)
      },
      // 上传文件之前的钩子：判断上传文件格式、大小等，若返回false则停止上传
      beforeUpload(file) {
        // 文件类型
        // const isType = file.type === 'application/vnd.ms-excel'
        // const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // const fileType = isType || isTypeComputer
        var fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
        // 判断文件格式
        if (!(fileType === 'xls' || fileType === 'xlsx' || fileType === 'doc' || fileType === 'docx' || fileType === 'pdf' || fileType === 'pptx' || fileType === 'ppt')) {
          this.$message.error('上传文件只能是xls/xlsx/pdf/doc/docx/pptx/ppt格式！')
        }
        // 文件大小限制为10M
        const fileLimit = file.size / 1024 / 1024 < 200
        if (!fileLimit) {
          this.$message.error('上传文件大小不超过200M！')
        }
        return fileType && fileLimit
      },
      // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
      uploadHttpRequest(param) {
        const formData = new FormData() // FormData对象，添加参数只能通过append('key', value)的形式添加
        formData.append('file', param.file) // 添加文件对象 要提交给后台的文件
        // formData.append('uploadType', this.rulesType)
        upload(formData).then(resp => {
          console.log(resp.data.data)
          if (resp.data.code === '200') {
            this.$notify({
              title: '成功',
              message: '上传成功',
              type: 'success'
            })
            this.form.url = resp.data.data.url
          }
        }).catch(err => {
          console.log('失败', err)
          param.onError() // 上传失败的文件会从文件列表中删除
        })
      },
      // 点击上传：手动上传到服务器，此时会触发组件的http-request
      submitUpload() {
        // if (!this.fileLists || !this.fileLists.length) {
        //   this.$notify.error({
        //     title: '错误',
        //     message: '请选择上传的文件',
        //     duration: 2000
        //   })
        this.$refs.upload.submit()
      },
      // 文件发生改变
      fileChange(file, fileList) {
        if (fileList.length > 0) {
          this.fileList = [fileList[fileList.length - 1]] // 展示最后一次选择的文件
        }
      },
      // 移除选择的文件
      fileRemove(file, fileList) {
        if (fileList.length < 1) {
          this.uploadDisabled = true // 未选择文件则禁用上传按钮
          this.$emit('submitUpload:visible', false) // 直接修改父组件的属性
        }
      },
      getOne () {
       const query = {
          classId: this.$route.query.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        findSubmitWork(query).then(resp => {
          if (resp.data.code === '200') {
            this.tableData = resp.data.data.rows
            this.total = resp.data.data.total
          }
        })
      },
      assessment () {
        this.centerDialogVisible = true
      },
      uploadBt() { // 附件作业提交
       if (this.form.url !== '') {
         // 新增
        if (this.form.id === '') {
           const query = {
              classId: this.$route.query.id,
              type: 1,
              workUrl: this.form.url,
              describe: this.form.describe
            }
            submitWork(query).then(resp => {
              if (resp.data.code === '0') {
                this.centerDialogVisible = false
                this.$notify({
                  title: '作业提交成功',
                  type: 'success'
                })
                this.form = {}
                this.getOne()
                this.form.id = ''
                // history.go(0) // 刷新当前页面
              } else {
                  this.$notify.info({
                    message: resp.data.msg
                  })
                }
            })
        } else {
          // 修改
            const query = {
              classId: this.$route.query.id,
              id: this.form.id,
              type: 1,
              workUrl: this.form.url,
              describe: this.form.describe
            }
             editWork(query).then(resp => {
              if (resp.data.code === '0') {
                this.centerDialogVisible = false
                this.$notify({
                  title: '作业修改成功',
                  type: 'success'
                })
                this.form = {}
                this.getOne()
              } else {
                this.$notify.info({
                  message: resp.data.msg
                })
              }
            })
        }
       } else {
         this.$notify.info({
              message: '请先点上传完文件再提交作业'
          })
       }
      },
      // 文本提交
      noAuth () {
        if (this.stuWork !== '') {
          // 新增
          if (this.form.id === '') {
             const query = {
                classId: this.$route.query.id,
                type: 2,
                stuWork: this.form.stuWork
              }
             submitWork(query).then(resp => {
              if (resp.data.code === '0') {
                this.centerDialogVisible = false
                this.$notify({
                  title: '作业提交成功',
                  type: 'success'
                })
                this.form = {}
                this.getOne()
                this.form.id = ''
              } else {
                this.$notify.info({
                  message: resp.data.msg
                })
              }
            })
          } else {
            // 修改
            const query = {
              classId: this.$route.query.id,
              id: this.form.id,
              type: 2,
              stuWork: this.form.stuWork
            }
             editWork(query).then(resp => {
              if (resp.data.code === '0') {
                this.centerDialogVisible = false
                this.$notify({
                  title: '作业修改成功',
                  type: 'success'
                })
                this.form = {}
                this.getOne()
              } else {
                this.$notify.info({
                  message: resp.data.msg
                })
              }
            })
          }
        } else {
         this.$notify.info({
              message: '作业内容不能为空'
          })
       }
      },
      editWork(row) {
        this.centerDialogVisible = true
        this.form = row
        // this.form.list.push({ url: this.form.workUrl })
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.getOne()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getOne()
      }
    }
  }
</script>

<style scoped>
  .dash-board {display: flex; background-color: white; height: 60px; padding: 10px 0;}
  .bg{margin-top: 15px;  padding: 20px; background-color: white;}
  .in1{font-size: 13px; position: absolute; top: 10px; left: 17px}
  .in2{font-size: 13px; position: absolute; top:10px; left: 330px}
  .in3{width: 160px; position: absolute; top: 14px;left: 400px}
  .spani{color: #2c2fd6}
  .e-inps{position: absolute; left: 100px; top: 14px}
  .row-bg{ padding: 10px 0px;}
  .row-text{line-height: 24px;}
</style>
